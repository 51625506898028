import React, { Fragment } from "react";

const ThankYouPage = () => (
  <Fragment>
    <section className="h-[calc(100%-410px)] bg-[url('../images/404-page/banner.png')] bg-center bg-cover flex items-center">
      <div className="container text-white">
        <h1 className="text-[52px] font-bold">Thank You</h1>
        <p>Thank you for your inquire!</p>
      </div>
    </section>
  </Fragment>
);

export default ThankYouPage;
